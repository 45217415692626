body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; /* Ensure the body and html elements take full width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding is included within the element's width/height */
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

p {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }

  p {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}